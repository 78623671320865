/* Updated tab bar styles */
.tab-bar {
  display: flex;
  justify-content: space-around;
  margin: 20px 0px;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}

.tab-bar button {
  padding: 10px 20px;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: #f69042;
  color: #fff;
}

.tab-bar button.active {
  background-color: #4d4d4d;
  color: #fff;
}

.tab-bar button:hover {
  background-color: #4d4d4d;
  color: #fff;
}

/* Updated heading styles */
h2 {
  margin: 20px 0px;
  text-align: center;
}

/* Scrollable content */
.scrollable-content {
  margin: 0 10% 10% 10%;
  max-height: calc(100vh - 300px);
  overflow-y: auto;
}
.scrollable-content h3{
  margin: 10px;
}
/* Event card container */
.event-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  transition: transform 0.3s ease;
}

/* Hover effect */
.event-card:hover {
  transform: translateY(-5px);
}

/* Event title */
.event-card h4 {
  margin-bottom: 10px;
  color: #333;
  font-size: 1.5rem;
}

/* Event description */
.event-card p {
  color: #666;
  line-height: 1.5;
}

/* Date and time */
.event-card .datetime {
  margin-top: 10px;
  color: #888;
}

/* Location */
.event-card .location {
  color: #888;
}

/* Image */
.event-card img {
  max-width: 100%;
  border-radius: 10px;
  margin-top: 20px;
}

/* Button container */
.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

/* Join button */
.join-button,
.share-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #f69042;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.join-button:hover,
.share-button:hover {
  background-color: #b35400;
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .event-card {
    padding: 15px;
  }
  .event-card h4 {
    font-size: 1.2rem;
  }
}

/* Join Via Code styles */
.join-via-code{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 350px);
}

.join-via-code-container {
  width: calc(100% - 50px);
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.input-container {
  display: flex;
  align-items: center;
}

.input-container input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 16px;
  border-color: #f69042;
}

.input-container input:focus {
  border-color: #f69042;
}

.input-container button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #f69042;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.input-container button:hover {
  background-color: #b35400;
}

/* Skeleton loader styles */
.skeleton {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0px;
  animation: pulse 1.5s infinite alternate;
}

/* Skeleton keyframe animation */
@keyframes pulse {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

.skeleton-title {
  width: 60%;
  height: 20px;
  margin-bottom: 10px;
  background-color: #ddd;
}

.skeleton-description {
  width: 100%;
  height: 60px;
  margin-bottom: 10px;
  background-color: #ddd;
}

.skeleton-datetime,
.skeleton-location,
.skeleton-image {
  width: 100%;
  height: 20px;
  margin-bottom: 10px;
  background-color: #ddd;
}

.skeleton-buttons {
  display: flex;
  justify-content: space-between;
}

.skeleton-buttons button {
  width: 45%;
  height: 40px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}