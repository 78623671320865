:root {
  --primary-color: #f69042;
  --hover-color: #b16226;
}

.profile-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  scroll-behavior: auto;
}

.profile-heading {
  font-size: 24px;
  color: #333;
}

.profile-details {
  margin-top: 20px;
}

.profile-details p {
  margin-bottom: 10px;
}

.loading-message {
  text-align: center;
  font-style: italic;
  color: #666;
}

.scrollable-content {
  margin: 0 10% 10% 10%;
  max-height: calc(100vh - 300px);
  overflow-y: auto;
}

.scrollable-profile-details {
    max-height: 400px; /* Adjust as needed */
    overflow-y: auto;
  }
  

/* UpdateUserModal.css */

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal.open {
  display: block;
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 20px;
  width: 80%;
  max-width: 500px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 10px;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 8px;
  margin-top: 3px;
  margin-bottom: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  background-color: var(--primary-color);
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: var(--hover-color);
}

/* Add this CSS to your profile.css file */

.carousel-container {
  width: 100%;
  max-width: 800px; /* Adjust as needed */
  margin: 0 auto;
  padding: 20px;
}

.carousel-slide {
  height: 300px; /* Adjust as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  overflow: hidden;
}

.carousel-slide-content {
  text-align: center;
  color: white;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}

.carousel-slide-content h4 {
  font-size: 24px;
  margin-bottom: 10px;
}

.carousel-slide-content p {
  font-size: 16px;
}

/* Customize carousel indicators */
.carousel-status {
  text-align: center;
  margin-top: 10px;
}

.carousel-status .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 0 5px;
  cursor: pointer;
}

.carousel-status .dot.active {
  background-color: #555;
}

/* Adjust the size of the dots */
.carousel .carousel-status .dot {
  width: 8px;
  height: 8px;
  margin: 0 3px;
}

/* Hide the arrows on the sides */
.carousel .control-prev.control-arrow,
.carousel .control-next.control-arrow {
  display: none;
}
