 * {
  margin: 0;
  padding: 0;
}
.backgroud-image {
  background-image: url("https://img.freepik.com/free-photo/happy-excited-friends-reunited_23-2149165087.jpg?size=626&ext=jpg&ga=GA1.1.119236241.1707224145&semt=country_rows_v1");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}
.main-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container {
  height: 60vh;
  background-color: rgb(68, 68, 68);
  /* border-color: brown; */
  padding: 26px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: aliceblue;
}

.container-switch {
  background-color: rgb(243, 79, 79);
  border-radius: 20px;
  margin: 20px;
}

.text_login {
  text-align: center;
  color: #ffffff;
  font-size: 48px;
  font-weight: 700;
}

.underline {
  width: 61px;
  height: 6px;
  background: #ffffff;
  border-radius: 9px;
}

.input {
  display: flex;
  align-items: center;
  margin: auto;
  margin-top: 10px;
  width: 400px;
  height: 80px;
  border-radius: 6px;
}

.input input {
  height: 50px;
  width: 300px;
  background: transparent;
  border: none;
  outline: none;
  color: #ffffff;
  font-size: 19px;
}
.input img {
  margin: 0px 30px;
  height: 20px;
  width: 20px;
}
.text-formating {
  margin-top: 27px;
  color: #797979;
  font-size: 18px;
  text-align: center;
}

.text-formating span {
  color: #f58013;
  cursor: pointer;
}
.submit_container {
  display: flex;
  gap: 30px;
  margin-bottom: 0;
  margin: 20px auto;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}
.submit {
  display: flex;
  text-align: center;
  width: 220px;
  padding: 20px;
  padding-left: 70px;
  color: #f58013;
  background: #ffffff;
  border-radius: 50px;
  font-size: 19px;
  font-weight: 700;
  cursor: pointer;
}
.gray {
  background: #eaeaea;
  color: #797979;
}
.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #eaeaea;
  transition: 0.3ms;
}
.login-btn {
  margin-top: 20px;
  align-items: center;
  border: 0;
  font-size: 24px;
  height: 50px;
  width: 400px;
  background: #f58013;
  color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
}

